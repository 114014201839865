<template>
    <div class="report_section">
        <div class="report_container">
            <div class="report_card">
                <div class="header">
                    <div class="user_info">
                        <img src="@/assets/images/table-user.png" alt="">
                        <div>
                            <h4>John Doe</h4>
                            <h3>Started Progress Tracking on 4th July, 2022</h3>
                        </div>
                    </div>
                    <div class="submission">
                        <h4>0/2</h4>
                        <h3>Submission</h3>
                    </div>
                </div>
            </div>
            <div class="section_header mt-5">
                <h2>Progress</h2>
            </div>
            <ul class="tracking_list">
                <li>
                    <h4>Participation Score: <span>50%</span></h4>
                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                        <div class="inner_circle">
                            <div class="score" style="transform: scale(0.5);">50<span>%</span></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2f7eed" :stroke-dasharray="100" :stroke-dashoffset="50"/>
                        </svg>
                    </div>
                </li>
                <li>
                    <h4>Progress Score: <span>8%</span></h4>
                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                        <div class="inner_circle">
                            <div class="score" style="transform: scale(0.5);">8<span>%</span></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2f7eed" :stroke-dasharray="100" :stroke-dashoffset="92"/>
                        </svg>
                    </div>
                </li>
            </ul>
            <div class="progress_tbl">
                <div class="section_header mt-5">
                    <h2>Weight</h2>
                </div>
                <div class="result_wpr">
                    <table>
                        <thead>
                            <tr>
                                <th>Goal</th>
                                <th>Start</th>
                                <th>Current</th>
                                <th>Percent of Goal</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><label>Weight:</label> 169 Pounds</td>
                                <td><label>Weight:</label> 178 Pounds</td>
                                <td><label>Weight:</label> 180 Pounds</td>
                                <td>
                                    <h6>20%</h6>
                                    <div class="status"><span style="width:20%;"></span></div>
                                </td>
                                <td>
                                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                        <div class="inner_circle">
                                            <div class="score" style="transform: scale(0.5);">20<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2f7eed" :stroke-dasharray="100" :stroke-dashoffset="80"/>
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="section_header mt-5">
                    <h2>Body Fat</h2>
                </div>
                <div class="result_wpr">
                    <table>
                        <thead>
                            <tr>
                                <th>Goal</th>
                                <th>Start</th>
                                <th>Current</th>
                                <th>Percent of Goal</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><label>Body Fat:</label> 169 Pounds</td>
                                <td><label>Body Fat:</label> 178 Pounds</td>
                                <td><label>Body Fat:</label> 180 Pounds</td>
                                <td>
                                    <h6>20%</h6>
                                    <div class="status"><span style="width:20%;"></span></div>
                                </td>
                                <td>
                                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                        <div class="inner_circle">
                                            <div class="score" style="transform: scale(0.5);">20<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2f7eed" :stroke-dasharray="100" :stroke-dashoffset="80"/>
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="section_header mt-5">
                    <h2>Circumference</h2>
                </div>
                <div class="result_wpr">
                    <table>
                        <thead>
                            <tr>
                                <th>Goal</th>
                                <th>Start</th>
                                <th>Current</th>
                                <th>Percent of Goal</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><label>Shoulders:</label> 40 Inches</td>
                                <td><label>Shoulders:</label> 37 Inches</td>
                                <td><label>Shoulders:</label> 37 Inches</td>
                                <td>
                                    <h6>20%</h6>
                                    <div class="status"><span style="width:20%;"></span></div>
                                </td>
                                <td>
                                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                        <div class="inner_circle">
                                            <div class="score" style="transform: scale(0.5);">20<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2f7eed" :stroke-dasharray="100" :stroke-dashoffset="80"/>
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><label>Chest:</label> 44 Inches</td>
                                <td><label>Chest:</label> 40 Inches</td>
                                <td><label>Chest:</label> 40 Inches</td>
                                <td>
                                    <h6>20%</h6>
                                    <div class="status"><span style="width:20%;"></span></div>
                                </td>
                                <td>
                                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                        <div class="inner_circle">
                                            <div class="score" style="transform: scale(0.5);">20<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2f7eed" :stroke-dasharray="100" :stroke-dashoffset="80"/>
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><label>Waist:</label> 30 Inches</td>
                                <td><label>Waist:</label> 33 Inches</td>
                                <td><label>Waist:</label> 33 Inches</td>
                                <td>
                                    <h6>20%</h6>
                                    <div class="status"><span style="width:20%;"></span></div>
                                </td>
                                <td>
                                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                        <div class="inner_circle">
                                            <div class="score" style="transform: scale(0.5);">20<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2f7eed" :stroke-dasharray="100" :stroke-dashoffset="80"/>
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><label>Hips:</label> 33 Inches</td>
                                <td><label>Hips:</label> 35 Inches</td>
                                <td><label>Hips:</label> 34 Inches</td>
                                <td>
                                    <h6>50%</h6>
                                    <div class="status"><span style="width:50%;"></span></div>
                                </td>
                                <td>
                                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                        <div class="inner_circle">
                                            <div class="score" style="transform: scale(0.5);">50<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2f7eed" :stroke-dasharray="100" :stroke-dashoffset="50"/>
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="section_header mt-5">
                <h2>Picture</h2>
            </div>
            <ul class="picture_section">
                <li v-for="(img, i) in imgs" :key="i">
                    <ul class="section_wpr">
                        <li v-for="(item, n) in img.items" :key="n">
                            <div class="section_title">
                                <h4>{{n == 0 ? 'Starting Front' : 'Current Front'}} <span>{{item.title}}</span></h4>
                            </div>
                            <div class="img_viewer">
                                <img :src="item.src" alt="">
                                <button type="button" class="lightbox_btn" @click="showMultiple(i, n)"><i class="fas fa-search-plus"></i></button>
                                <button type="button" class="download_btn"><i class="fas fa-download"></i></button>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
            <vue-easy-lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs[imgsItem].items"
            :index="index"
            @hide="handleHide"
            ></vue-easy-lightbox>
            <div class="section_header mt-5">
                <h2>History</h2>
            </div>
            <div class="result_wpr">
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Week</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>23rd May, 2022</td>
                            <td>Week 1</td>
                            <td><button @click="history = true"><i class="far fa-edit"></i></button></td>
                        </tr>
                        <tr>
                            <td>23rd May, 2022</td>
                            <td>Week 1</td>
                            <td><button @click="history = true"><i class="far fa-edit"></i></button></td>
                        </tr>
                        <tr>
                            <td>23rd May, 2022</td>
                            <td>Week 1</td>
                            <td><button @click="history = true"><i class="far fa-edit"></i></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal" v-if="history" :class="{active : history}" @click.self="closeBar()">
                <div class="modal_container">
                    <div class="modal_header border-bottom">
                        <h1>Progress Scenario <span>View History</span></h1>
                        <button class="close_btn left_out" @click="history = false"><i class="fas fa-long-arrow-alt-right"></i></button>
                    </div>
                    <div class="modal_body">
                        <div class="setting_wpr">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Are you submitting progress pics with this report?</label>
                                    <div class="grp_wpr">
                                        <div class="box_item">
                                            <label for="spr">
                                                <input type="radio" value="yes" id="spr" v-model="spr" class="mr-2">
                                                <h5>Yes</h5>
                                            </label>
                                        </div>
                                        <div class="box_item">
                                            <label for="no_spr">
                                                <input type="radio" value="no" id="no_spr" v-model="spr" class="mr-2">
                                                <h5>No</h5>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h3 class="sub_header">
                                Front Picture
                            </h3>
                            <div class="img_wpr">
                                <img src="@/assets/images/free-image3.jpg" alt="">
                            </div>
                            <h3 class="sub_header">
                                Side Picture
                            </h3>
                            <div class="img_wpr">
                                <img src="@/assets/images/free-image5.jpg" alt="">
                            </div>
                            <h3 class="sub_header">
                                Back Picture
                            </h3>
                            <div class="img_wpr">
                                <img src="@/assets/images/free-image6.jpg" alt="">
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Caption</label>
                                    <div class="field_wpr">
                                        <textarea name="" id="" cols="30" rows="10" placeholder="I'm down 15lbs already.."></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">What is your weight today?</label>
                                    <div class="field_wpr has_suffix">
                                        <input type="number" placeholder="ex: 180">
                                        <span class="suffix">Pounds</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">What is your body fat percentage today?</label>
                                    <div class="field_wpr has_suffix">
                                        <input type="number" placeholder="ex: 21">
                                        <span class="suffix">%</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp pb-0">
                                <div class="group_item">
                                    <label class="input_label">What are your circumference measurements today?</label>
                                </div>
                            </div>
                            <div class="edit_section">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Shoulders</label>
                                        <div class="field_wpr has_suffix">
                                            <input type="number" placeholder="ex: 37">
                                            <span class="suffix">Inches</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Chest</label>
                                        <div class="field_wpr has_suffix">
                                            <input type="number" placeholder="ex: 40">
                                            <span class="suffix">Inches</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Waist</label>
                                        <div class="field_wpr has_suffix">
                                            <input type="number" placeholder="ex: 33">
                                            <span class="suffix">Inches</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Hips</label>
                                        <div class="field_wpr has_suffix">
                                            <input type="number" placeholder="ex: 34">
                                            <span class="suffix">Inches</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal_footer">
                        <button class="btn cancel_btn" @click="history = false">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Report',
    data(){
        return{
            history: false,
            spr: 'yes',
            visible: false,
            imgsItem: 0,
            index: null ,
            imgs : [
                {
                    items:[
                        {
                            title: '23rd April, 2022',
                            src: require('@/assets/images/free-image3.jpg')
                        },
                        {
                            title: '24rd May, 2022',
                            src: require('@/assets/images/free-image3.jpg')
                        }
                    ]
                },
                {
                    items:[
                        {
                            title: '23rd April, 2022',
                            src: require('@/assets/images/free-image5.jpg')
                        },
                        {
                            title: '24rd May, 2022',
                            src: require('@/assets/images/free-image5.jpg')
                        }
                    ]
                },
                {
                    items:[
                        {
                            title: '23rd April, 2022',
                            src: require('@/assets/images/free-image6.jpg')
                        },
                        {
                            title: '24rd May, 2022',
                            src: require('@/assets/images/free-image6.jpg')
                        }
                    ]
                },
            ],
        }
    },
    methods:{
        showMultiple(item, el) {
            this.imgsItem = item,
            this.index = el;
            this.show()
        },
        show() {
            this.visible = true
        },
        handleHide() {
            this.visible = false
        },
    }
}
</script>
<style scoped>
    .report_section{
        padding: 50px;
    }
    .report_container{
        max-width: 1170px;
        padding: 0 20px;
        margin: 0 auto;
    }
    .report_card{
        border: 1px solid #eaeaea;
        border-radius: 6px;
    }
    .report_card .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px;
    }
    .report_card .user_info{
        display: flex;
        text-align: left;
    }
    .report_card .user_info img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin-right: 10px;
        border: 1px solid #ccc;
    }
    .report_card .header h4 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #32373b;
        margin-bottom: 5px;
    }
    .report_card .header h3 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #32373b;
        margin-bottom: 3px;
    }
    /* .tracking_list li {
        width: 50%;
        padding: 15px 30px;
        text-align: left;
    } */
    .tracking_list li h4 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #32373b;
        /* margin-left: 10px; */
    }
    .tracking_list li h4 span{
        font-size: 18px;
        line-height: 23px;
        display: block;
        color: #121525;
        padding-top: 5px;
    }
    .tracking_list .score_circle{
        justify-content: flex-end;
    }
    .result_wpr{
        border-color: #eaeaea;
        margin-top: 12px;
    }
    .result_wpr table thead{
        border: 0;
        border-bottom: 1px solid #ddd;
    }
    .result_wpr table th, .result_wpr table td, .result_wpr table td:first-child, .result_wpr table th:first-child {
        width: auto;
        padding: 20px;
        text-align: left;
    }
    .result_wpr table th{
        background: #f5f5f5;
    }
    .progress_tbl .result_wpr table td:not(:last-child){
        width: 22%;
    }
    .result_wpr table td label{
        font-weight: 500;
    }
    .result_wpr table td h6{
        font-size: 11px;
        line-height: 15px;
        color: #2f7eed;
        font-weight: 500;
        text-align: right;
    }
    .result_wpr table td .status{
        height: 4px;
        border-radius: 2px;
        background: #eaeaea;
        position: relative;
    }
    .result_wpr table td .status span{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 2px;
        background: #2f7fed;
    }
    .result_wpr table td button{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 14px;
        background: #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .picture_section{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }
    .picture_section > li{
        padding: 5px 15px;
        width: 33.333%;
    }
    .picture_section > li .section_wpr{
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        display: flex;
        overflow: hidden;
    }
    .picture_section .section_wpr li:not(:last-child){
        border-right: 1px solid #eaeaea;
    }
    .picture_section .section_wpr li{
        flex: 1;
        line-height: 0;
    }
    .picture_section .section_wpr li .section_title{
        padding: 8px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e9e9e9;
        background: #f9f9f9;
    }
    .picture_section .section_wpr li .section_title h4{
        font-size: 12px;
        line-height: 15px;
        font-weight: 600;
        text-align: left;
        color: #121525;
    }
    .picture_section .section_wpr li .section_title h4 span{
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        display: block;
        padding-top: 3px;
        color: #757575;
    }
    .picture_section .section_wpr li .section_title i{
        font-size: 12px;
        width: 25px;
        height: 25px;
        box-shadow: 0 1px 5px  rgba(0,0,0,0.15);
        border: 1px solid #e9e9e9;
        border-radius: 50%;
        margin-left: 5px;
        color: #2f7eed;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .picture_section .section_wpr li img{
        width: 100%;
    }
    .grp_wpr{
        display: flex;
        margin: 0 -10px;
    }
    .box_item{
        flex: 1 0 50%;
        padding: 5px 10px;
    }
    .grp_wpr label{
        border: 1px solid #CBCDD3;
        border-radius: 6px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
    }
    .img_wpr img{
        width: 100%;
        height: 220px;
        border-radius: 6px;
        object-fit: cover;
    }
    .img_viewer{
        position: relative;
        overflow: hidden;
    }
    .img_viewer .lightbox_btn{
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: -20px;
        top: 10px;
        z-index: 1;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    .img_viewer .download_btn{
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: -20px;
        top: 10px;
        z-index: 1;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    .img_viewer:hover .lightbox_btn{
        left: 10px;
    }
    .img_viewer:hover .download_btn{
        right: 10px;
    }
</style>
